<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div className="animated">
      <b-form @submit.prevent="onSubmit" novalidate>
        <b-card class="p-0 m-0">
          <b-card-header>
            <i class="icon-menu mr-1"></i> Data ICH Trade & Customer ID
          </b-card-header>
          <b-card-body>
            <b-row>
              <!-- Type Transaction -->
              <b-col sm="12">
                <b-form-group class="mb-3">
                  <label for="sp">
                    Type Transaction
                  </label>
                  <b-form-select
                    id="sp"
                    v-model="form.type"
                    :options="typeOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <!-- Upload File -->
              <b-col sm="12">
                <b-form-group class="mb-3">
                  <label for="sp">
                    Upload File
                  </label>
                  <div
                    class="parent-wrapper-input-file d-flex  justify-content-between"
                    :class="{
                      'align-items-start': errors.format,
                      'align-items-center': !errors.format
                    }"
                  >
                    <div
                      class="d-flex align-items-start flex-column"
                      style="width: 100%;"
                    >
                      <div
                        class="wrapper-input-file"
                        :class="{
                          'is-invalid': errors.format
                        }"
                      >
                        <i class="fa fa-upload" aria-hidden="true"></i>
                        <b-form-file
                          v-model="form.file"
                          class="input-file-custom"
                          placeholder="Upload File"
                          drop-placeholder="Drop file here"
                          accept=".xls, .xlsx, .csv"
                          @change="checkFileFormat"
                        ></b-form-file>
                      </div>
                      <span class="text-danger" v-show="errors.format">{{
                        errors.format
                      }}</span>
                    </div>
                    <b-button
                      class="btn-template"
                      variant="success"
                      @click="handleDownloadTemplte"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                      Download Template
                    </b-button>
                  </div>
                </b-form-group>
              </b-col>
              <b-col sm="12 mt-3">
                <div>
                  <p class="mb-1">Notes</p>
                  <ul class="notes mb-5">
                    <li>
                      <p>
                        To avoid a timeout, if the data is more than 10,000
                        rows, please upload it in multiple batches
                      </p>
                    </li>
                    <li>
                      <p>
                        Uploaded data cannot be downloaded again, but it will be
                        automatically downloaded.
                      </p>
                    </li>
                  </ul>
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="!(form.type && form.file) || errors.format"
                  >
                    Submit
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-form>
      <b-modal
        id="modal-center"
        v-if="this.modal"
        size="sm"
        v-model="modal"
        @ok="handleModal"
        centered
        hide-header
        hide-footer
        style="width: 340px;"
        backdrop="static"
        no-close-on-backdrop
      >
        <b-row class="p-4">
          <b-col
            class="d-flex align-items-center justify-content-center flex-column text-center"
          >
            <b-spinner
              style="width: 3rem; height: 3rem; border-radius: 100%; color: #05dbb2; border-width: 6px;"
              label="Large Spinner"
            ></b-spinner>
            <h3 class="my-4 font-weight-bold">Please Wait!</h3>
            <p>Do not refresh or close the page during the process</p>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ich-trade",
  components: {},
  data() {
    return {
      form: {
        type: null,
        file: null
      },
      expand: {},
      modal: false,
      isLoading: false,
      errors: {
        code: "",
        status: "",
        message: "",
        format: null
      },
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      typeOptions: [
        { value: null, text: "Choose type transaction" },
        { value: "gold_transaction", text: "In App" },
        { value: "partner_gold_transaction", text: "Partners" }
      ]
    };
  },
  methods: {
    handleDownloadTemplte() {
      this.$http
        .get("inject/gold-trx/encode-hashed-id/template")
        .then(result => {
          this.isLoading = false;
          const res = result.data.meta;
          window.location.href = res?.data;
        })
        .catch(error => {
          const err = error.response;
          if (err) {
            const message = err.data.meta.message;

            this.isLoading = false;
            this.errors.status = err.status;
            this.errors.code = err.data.meta.code;
            this.errors.message = message;

            if (this.errors.code == 404) {
              this.$swal.fire("Failed!", message, "error").then(() => {
                location.reload();
              });
            }

            if (this.errors.code == 400) {
              this.$swal.fire("Failed!", message, "error");
            }
          }
        });
    },
    onSubmit() {
      this.handleModal();

      const formData = new FormData();
      formData.append("type", this.form.type);
      formData.append("file", this.form.file);

      this.$http
        .post("inject/gold-trx/encode-hashed-id", formData)
        .then(response => {
          this.handleModal();
          const res = response.data.meta;
          window.location.href = res?.data;
        })
        .catch(error => {
          const err = error.response;

          if (err) {
            this.handleModal();
            this.errors.code = err.status;
            this.errors.message = err.data.meta.message;
            this.errors.status = err.data.meta.status;

            if (err.status === 404) {
              this.$swal
                .fire("Failed!", `${this.errors.message}`, "error")
                .then(() => location.reload());
            } else {
              this.$swal.fire("Failed!", this.errors.message, "error");
            }
          }
        });
    },
    checkFileFormat(e) {
      let file = e.target.files[0];
      let extension = file.name.split(".").pop();

      if (extension !== "xls" && extension !== "xlsx") {
        this.errors.format = "File format must be xls, xlsx, csv";
        this.form.file = null;
      } else {
        this.errors.format = null;
      }
    },
    handleModal() {
      this.modal = !this.modal;
    }
  }
};
</script>

<style>
.parent-wrapper-input-file {
  gap: 16px;
}

.wrapper-input-file {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}
.wrapper-input-file.is-invalid {
  align-items: start;
  border-color: red;
}

.wrapper-input-file i {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99999;
  color: #909090;
}

.input-file-custom .custom-file-label {
  padding: 0.375rem 0.75rem 0.375rem 2rem;
  border: none;
  margin: unset;
}

.input-file-custom label::after {
  display: none;
}

.btn-template {
  width: 220px;
}

.notes {
  padding-inline-start: 16px;
}
</style>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
